/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --white: #ffffff;
  --purple: #611c73;
  --lavendar: #d494fb;
  /* --periwinkle: #b6caff; */
  /* --aqua: #b9e4f2; */
  /* --mint: #d6f9ea; */
  --ruby: #fe4672;
  --darkPurple: #391a52;
  --cream: #fef9f1;
  /* --primaryCream: #f8f0ec; */
  /* --secondaryCream: #fef0eb; */
  /* --darkPurple-40: #afa0b1; */
  /* --purple-60: #a075a5; */
  --blackOlive: #3E3E3E;
  --mistyCream: #EEE3E4;
  --gray-50: #B6B6B6;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Manrope";
}

.container {
  max-width: 1440px !important;
}

#landing_section {
  animation-name: fadding;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 100ms;
}

@keyframes fadding {
  0% {
    background: #391A52;
  }

  20% {
    background: #FE4672;
  }

  40% {
    background: #D494FB;
  }

  60% {
    background: #B6CAFF;
  }

  80% {
    background: #B9E4F2;
  }

  100% {
    background: #D6F9EA;
  }
}



#landing_section .landing_div_1 {
  background: #001F45;
  border-radius: 0 400px 0 0;
  -webkit-border-radius: 0 400px 0 0;
  -moz-border-radius: 0 400px 0 0;
  -ms-border-radius: 0 400px 0 0;
  -o-border-radius: 0 400px 0 0;
  position: relative;
  /*min-height: 900px;*/
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 5%;
  /* padding-top: 120px; */
  /*background-image: url('../Images/landing_img.svg');
  /* background-image: url('../Images/banner.png'); */
  /* background-image: url('../Images/Union.png'); */
  background-image: url('../Images/2.png');
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  /*background-size:800px;*/
  /* background-size:40%; */
  background-size: contain;
  background-position: right;
  background-position: bottom 0 right 0;
}

#landing_section .welcome_heading h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 84px;
  color: var(--white);
  margin-bottom: 0;
}

#landing_section .welcome_heading p {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  /* line-height: 74px; */
  color: var(--white);
}

/* #landing_section .landing_div_2 {
  background: url('../Images/Union.svg');
  background-position: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh; 
} */
/* #landing_section .landing_div_3 {
  background: url('../Images/landing_img.svg');
  background-position: 100% 100%;
  background-size: 50%;
  background-repeat: no-repeat;
  min-height: 100vh; 
} */

#landing_section .landingImg {
  padding: 100px;
  position: absolute;
  right: 0;
  bottom: 0;
  /* width: 100%; */
  display: none;
}

/* ************ home_section ************ */

#home_section {
  background-color: var(--cream);
}

.my_navbar {
  padding: 26px 56px;
}

.my_navbar .navbar-brand {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-toggler {
  border: none;
  filter: invert(1);
  -webkit-filter: invert(1);
  padding: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.my_navbar .tcareNavbarLogo {
  max-width: 150px;
}

.my_navbar .prudentialLogoBlue {
  max-width: 160px;
}

.navbar_divider {
  margin: 0 18px 0 20px;
  border-right: 2px solid;

}

#home_hero_section {
  padding-top: 50px;
  /* padding-bottom: 50px; */
  background-color: var(--cream);
}

#home_hero_section .hero_outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 76px;
  padding-bottom: 253px;
  overflow: clip;
}

#home_hero_section .hero_left {
  /* max-width: 638px; */
  max-width: 615px;
  /* padding-bottom: 304px; */
}

#home_hero_section .hero_left h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 70px;
  line-height: 96px;
  color: var(--darkPurple);
  width: 638px;
}

#home_hero_section .hero_left p {
  max-width: 572px;
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: var(--blackOlive);
  margin-bottom: 25px;

}

.tcareConnectBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--ruby);
  border-radius: 56px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: var(--cream) !important;
  height: 53px;
  width: fit-content;
  text-decoration: none;
  padding: 0 20px;
}

.tcareConnectBtn img {
  margin-right: 10px;
}

.tcareConnectBtn:hover {
  background-color: var(--purple);
}

#home_hero_section .hero_right {
  /* margin-left: auto; */
  /* width: 100%; */
  position: relative;
  /* background: url('../Images/bg_video_card.png'); */
  background-position: bottom 0 left 0;
  /* height:auto; */
  background-repeat: no-repeat;
  background-size: contain;
  /* overflow: clip; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  max-width: 725px;
  margin-left: auto;
  padding-left: 64px;
}

#home_hero_section .hero_right video {
  /* position: absolute; */
  z-index: 5;
  /* width: 100%; */
  border-radius: 56px;
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  -o-border-radius: 56px;
  /* right: -50px; */
  max-height: 471px;
}

/* #home_hero_section .hero_right .bg_video_card {
  display: flex;
  flex-direction: column;
  position: relative;
}*/

#home_hero_section .hero_right .card_1 img {
  position: absolute;
}

#home_hero_section .hero_right .card_1 img.card_1_lavender {
  max-height: 398px;
  bottom: -163px;
  left: 46px;
}

#home_hero_section .hero_right .card_1 img.card_1_ruby {
  max-height: 337px;
  bottom: -102px;
  left: 249px;
}

#home_hero_section .hero_right .card_1 img.card_1_mint {
  max-height: 403px;
  bottom: -140px;
  left: 0px;
}

#home_hero_section .hero_right .card_1 img.card_1_periwinkle {
  max-height: 398px;
  bottom: -79px;
  left: 76px;
}



/*#home_hero_section .hero_right .bg_video_card img:nth-of-type(1) {
  z-index: 1;
  top: 130px;
}

#home_hero_section .hero_right .bg_video_card img:nth-of-type(2) {
  z-index: 2;
  left: 100px;
}

#home_hero_section .hero_right .bg_video_card img:nth-of-type(3) {
  z-index: 3;
  right: 40px;
}

#home_hero_section .hero_right .bg_video_card img:nth-of-type(4) {
  z-index: 4;
  top: 50px;
} */
#description_section {
  background-color: var(--cream);
  /* padding: 40px 0 60px 0; */
  padding-bottom: 113px;
}

#description_section .description {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 302px;
  align-items: center;
  background-color: var(--darkPurple);
  color: var(--cream);
  padding: 0 132px;
  margin: 0 66px;

  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;

  border-radius: 72px;
  -webkit-border-radius: 72px;
  -moz-border-radius: 72px;
  -ms-border-radius: 72px;
  -o-border-radius: 72px;
}

#description_section .description p {
  max-width: 635px;
}


#video_2_section {
  background-color: var(--darkPurple);
  overflow: clip;
  padding-bottom: 312px;
  height: 100%;
  /* background-image: url('../Images/3.png'); */
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 70% 43%;
}

/* #video_2_section .video_2_container {
  max-width: 100%;
} */

/* #video_2_section .video_2_outer {
  position: relative;
} */

#video_2_section .video_2_outer video {
  border-radius: 0px 0px 0px 224px;
  /* height: 841px; */
  width: 100%;
  display: block;
  z-index: 5;
  position: relative;
}

/* #video_2_section .video_2_inner_wrapper {
  max-width: 1320px;
  margin: 0 auto;
} */
#video_2_section .video_2_outer {
  position: relative;
}

/* #video_2_section .video_2_outer .video_2_col {
  position: absolute;
  top: 111px;
} */

#video_2_section .video_2_inner {
  max-width: 470px;
  position: absolute;
  top: 111px;
  left: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 56px;
  gap: 32px;
  background: rgba(254, 249, 241, 0.7);
  backdrop-filter: blur(18px);
  border-radius: 48px;
  z-index: 5;
}

#video_2_section .video_2_inner h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 58px;
  color: var(--darkPurple);
}

#video_2_section .video_2_inner p {
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: var(--blackOlive);
}

#video_2_section .video_2_outer .card_2 img {
  position: absolute;
  max-height: 398px;
}

#video_2_section .video_2_outer .card_2 img.card_2_Mint {
  bottom: -210px;
  left: 665px;
  z-index: 1;
}

#video_2_section .video_2_outer .card_2 img.card_2_Ruby {
  bottom: -103px;
  left: 208px;
  z-index: 2;
}

#video_2_section .video_2_outer .card_2 img.card_2_Periwinkle {
  bottom: -57px;
  left: 133px;
  z-index: 3;
}

#tcare_services_section {
  background-color: var(--darkPurple);
  padding-top: 93px;
}

#tcare_services_section .services_header {
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 87px;
  color: var(--cream);
  text-align: center;
  padding-bottom: 88px;
}

#tcare_services_section .services_row {
  padding-bottom: 97px;
  padding-left: 76px;
  padding-right: 76px;
}

#tcare_services_section .services_card {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: var(--cream);
}

#tcare_services_section .services_card p {
  padding-top: 16px;
}

#video_3_section {
  background-color: var(--darkPurple);
  overflow: clip;
  padding-top: 212px;
  /* background-image: url('../Images/5.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: 70% 43%; */
}

/* #video_3_section .video_3_container {
  max-width: 100%;
} */

/* #video_3_section .video_3_outer {
  position: relative;
} */

#video_3_section .video_3_outer video {
  border-radius: 224px 0px 0px 0px;
  /* height: 841px; */
  width: 100%;
  display: block;
  position: relative;
  z-index: 5;
}

/* 
#video_3_section .video_3_inner_wrapper {
  max-width: 1320px;
  margin: 0 auto;
} */
#video_3_section .video_3_outer {
  position: relative;
}

/* #video_3_section .video_3_outer .video_3_col {
  position: absolute;
  bottom: 111px;
  display: flex;
  justify-content: end;
} */

#video_3_section .video_3_inner {
  max-width: 470px;
  position: absolute;
  bottom: 121px;
  /* left: 120px; */
  right: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 56px;
  gap: 32px;
  background: rgba(254, 249, 241, 0.7);
  backdrop-filter: blur(18px);
  border-radius: 48px;
  z-index: 5;
}

#video_3_section .video_3_inner h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color: var(--darkPurple);
}

#video_3_section .video_3_inner p {
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: var(--blackOlive);
}

#video_3_section .video_3_outer .card_3 img {
  position: absolute;
  max-height: 398px;
}

#video_3_section .video_3_outer .card_3 img.card_3_periwinkle {
  z-index: 3;
  top: -83px;
  left: 106px;
}

#video_3_section .video_3_outer .card_3 img.card_3_mint {
  z-index: 2;
  top: -141px;
  left: 640px;
}

#video_3_section .video_3_outer .card_3 img.card_3_darkPurple {
  z-index: 1;
  top: -185px;
  left: 575px;
}

#faq_section {
  background-color: var(--darkPurple);
}

#faq_section .wrapper {
  background-color: var(--mistyCream);
  border-radius: 0px 0px 224px 0px;
  padding-top: 107px;
  padding-left: 108px;
  padding-right: 108px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 146px;
}

#faq_section .faq_header {
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 87px;
  color: var(--darkPurple);
  width: 509px;
  height: fit-content;
}

#faq_section ul {
  padding-top: 25px;
  max-width: 596px;
  padding-left: 0;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 737px; */
}

#faq_section ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  list-style: none;
  /* margin-bottom: 80px; */
  min-height: 130px;
}

#faq_section ul li:nth-of-type(3) {
  min-height: 160px;
}

#faq_section ul li:nth-of-type(4) {
  min-height: 200px;
}

#faq_section ul li a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  color: var(--darkPurple);

}

#faq_section ul li a img {
  padding-left: 30px;
}

#faq_section ul li a[aria-expanded="true"] img {
  padding-left: 0;
  padding-right: 30px;
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}

#faq_section ul li .collapse_div {
  padding-right: 100px;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
  margin-top: 20px;
}

#faq_section ul li .collapse_div a {
  display: inline-block;
  color: var(--ruby);
}

#faq_section ul li .collapse_div p strong {
  color: var(--ruby);
}

#bottom_section {
  background-color: var(--darkPurple);
  position: relative;
}

#bottom_section .wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */

  max-height: 1048px;
}

#bottom_section .left {
  max-width: 629px;
  z-index: 1;

  position: relative;
  top: -200px;
}

#bottom_section .left img {
  width: 100%;
}

/* #bottom_section .bottom_side_banner {
  position: absolute;
  left: 0;
  top: -200px;
  max-width: 225px;
  height: -webkit-fill-available;
  height: fill-available;
} */
#bottom_section .wrapper .right {
  /* max-width: 595px; */
  max-width: 650px;
  padding-top: 108px;
  padding-left: 91px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#bottom_section .wrapper .right h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 109px;
  color: var(--cream);
  /* max-width: 595px; */
  margin-bottom: 40px;
}

#bottom_section .wrapper .card_4 img {
  position: absolute;
  max-width: 225px;
}

#bottom_section .wrapper .card_4 img.card_4_aqua {
  top: -199px;
  left: 0;
}

#bottom_section .wrapper .card_4 img.card_4_purple {
  bottom: -200px;
  left: 0;
  z-index: 1;
}

#footer_section {
  background-color: var(--darkPurple);
  margin: 0;
}

#footer_section .landing_footer {
  max-width: 700px;
  margin-left: auto;
  padding-right: 120px;

  width: 100%;
  max-width: 100%;
}

.footer_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  /* background-color: var(--darkPurple); */
  border-top: 1px solid #040205;
}

.footer_div p,
.footer_div a {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--cream);
  opacity: 0.6;
  text-decoration: none;
  margin-bottom: 0;

}

/* **************************** */
/* #myFooter_section {
  background-color: var(--darkPurple);
  height: 1048px;
  color: var(--cream);
}

#myFooter_section .myFooter_left img{
  bottom: 0px;
}

#myFooter_section .myFooter_right h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 109px;
  color: var(--cream);
  margin-bottom: 40px;
} */


/* ***************************** */


/* support page */

#support_section {
  background-color: var(--darkPurple);
}

#support_section .navbar_divider {
  border-color: var(--cream);
}

.visitTcareBtn {
  color: var(--cream) !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 20px;
  height: 53px;
  text-decoration: none;
  background: rgba(254, 249, 241, 0.2);
  border-radius: 56px;
  width: fit-content;
}

.visitTcareBtn img {
  padding-right: 13px;
}

.visitTcareBtn:hover {
  background-color: var(--ruby);
}

#support_hero_section {
  background-color: var(--darkPurple);
}

#support_hero_section .hero_main {
  padding: 76px 0 110px 76px;
}

#support_hero_section h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 70px;
  line-height: 96px;
  margin-bottom: 16px;
  color: var(--ruby);
}

#support_hero_section p {
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: var(--cream);
}

#support_main_section {
  background-color: var(--darkPurple);
}

#support_main_section .wrapper {
  background-color: var(--cream);
  padding: 94px 76px 50px 76px;
  border-radius: 0px 224px 0px 0px;
  overflow: clip;
}

#support_main_section .wrapper .heading {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  max-width: 904px;
  margin-bottom: 56px;
  color: var(--darkPurple);
}

#support_main_section .wrapper .main_row {
  margin-bottom: 38px;
}

#support_main_section .wrapper .main_outer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 48px 40px;
  gap: 64px;
  background: rgba(57, 26, 82, 0.05);
  border-radius: 56px;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}

#support_main_section .main_outer .main_left {
  width: 629px;
}

#support_main_section .main_outer .main_left h3 {
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 50px;
  color: var(--darkPurple);
}

#support_main_section .main_outer .main_left p {
  margin-bottom: 32px;
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: var(--blackOlive);
}

#support_main_section .main_outer .main_left .watchVideoBtn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  height: 53px;
  background-color: var(--ruby);
  border-radius: 56px;
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  -o-border-radius: 56px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: var(--cream);
  width: fit-content;
  text-decoration: none;
}

#support_main_section .main_outer .main_left .watchVideoBtn:hover {
  background-color: var(--purple);
}

#support_main_section .main_outer .main_right {
  max-width: 491px;
}

#support_main_section .main_outer .main_right img {
  width: 100%;
  border-radius: 56px;
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  -o-border-radius: 56px;
}

#support_main_section .main_outer .main_right video {
  width: 100%;
  border-radius: 56px;
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  -o-border-radius: 56px;
  object-fit: cover;
  /* object-fit: fill; */
  max-width: 491px;
  height: 331px;
  border: none !important;
  outline: none !important;
}

#support_footer {
  background-color: var(--cream);
}

#support_footer .footer_div {
  border-top: 1px solid var(--gray-50);
}

#support_footer .wrapper {
  padding: 0 108px;
}

#support_footer .footer_div p,
#support_footer .footer_div a {
  color: var(--blackOlive);
}

section {
  max-width: 1440px;
  margin: 0 auto;
}