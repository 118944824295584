@media screen and (max-width: 1400px) {
    #landing_section .landing_div_1 {
        border-radius: 0 230px 0 0;
        -webkit-border-radius: 0 230px 0 0;
        -moz-border-radius: 0 230px 0 0;
        -ms-border-radius: 0 230px 0 0;
        -o-border-radius: 0 230px 0 0;
    }

    #landing_section .welcome_heading h1 {
        font-size: 54px;
        line-height: 74px;
    }

    #home_hero_section .hero_right {
        max-width: 654px;
    }

    #home_hero_section .hero_right video {
        max-height: 432px;
    }
}

@media screen and (min-width:993px) and (max-width:1366px) {
    #home_hero_section .hero_outer {
        flex-wrap: wrap;
    }

    #home_hero_section .hero_left {
        max-width: 50%;
        width: 50%;
    }

    #home_hero_section .hero_left h1 {
        width: 100%;
    }

    #home_hero_section .hero_right {
        max-width: 50%;
    }

    #home_hero_section .hero_right video {
        max-width: 170%;
    }

    #home_hero_section .hero_right .card_1 img.card_1_lavender {
        width: 100%;
        /* bottom: -24%; */
        bottom: -17%;
    }

    #home_hero_section .hero_right .card_1 img.card_1_ruby {
        width: 100%;
        /* bottom: -12%; */
        bottom: -7%;
    }

    #home_hero_section .hero_right .card_1 img.card_1_mint {
        width: 83%;
        /* bottom: -16%; */
        bottom: -11%;
    }

    #home_hero_section .hero_right .card_1 img.card_1_periwinkle {
        width: 88%;
        width: -webkit-fill-available;
        /* bottom: -8%; */
        bottom: -5%;
    }

    #description_section .description {
        padding: 40px;
        justify-content: space-evenly;
    }

    #video_2_section .video_2_inner {
        top: 8%;
        padding: 3%;

    }

    #video_2_section .video_2_inner h3 {
        margin-bottom: 0;
    }

    #video_2_section .video_2_inner p {
        margin-bottom: 0;
    }

    #video_2_section .video_2_outer .card_2 img.card_2_Mint {
        left: 60%;
        width: -webkit-fill-available;
        bottom: -30%;
    }

    #video_2_section .video_2_outer .card_2 img.card_2_Ruby {
        bottom: -20%;
        width: 60%;
    }

    #video_2_section .video_2_outer .card_2 img.card_2_Periwinkle {
        bottom: -10%;
        width: 40%;
    }

    #tcare_services_section .services_card {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-height: 190px;
    }

    #video_3_section .video_3_outer .card_3 img.card_3_periwinkle {
        width: 50%;
        top: -10%;
    }

    #video_3_section .video_3_outer .card_3 img.card_3_mint {
        width: 20%;
        top: -20%;
        left: 47%;
    }

    #video_3_section .video_3_outer .card_3 img.card_3_darkPurple {
        width: 50%;
        top: -25%;
        left: 42%;
    }

    #video_3_section .video_3_inner {
        bottom: 10%;
    }

    #faq_section .faq_header {
        width: 45%;
    }

    #faq_section ul {
        width: 55%;
    }


    #bottom_section .wrapper .card_4 {
        display: none;
    }

    #bottom_section .wrapper .right {
        margin-left: auto;
        padding-right: 76px;
        padding-left: 0;
    }

}

@media screen and (min-width:993px) and (max-width:1295px) {
    #bottom_section .wrapper {
        height: 740px;
    }

    #bottom_section .left {
        top: -19%;
    }

    #bottom_section .left img {
        width: 60%;
        min-width: 443px;
    }

    #bottom_section .wrapper .right {
        padding-top: 40px;
    }

    #bottom_section .wrapper .right h2 {
        font-size: 60px;
        line-height: 89px;
        margin-bottom: 0;
    }
}

@media screen and (min-width:501px) and (max-width:992px) {
    .my_navbar {
        padding: 30px 10px;
    }

    .navbar-collapse .visitTcareBtn {
        margin-top: 30px;
    }

    #home_hero_section {
        padding-top: 30px;
    }

    #home_hero_section .hero_outer {
        padding: 0 30px;
        padding-bottom: 190px;
    }

    #home_hero_section .hero_left {
        max-width: 100%;
        width: 100%;
    }

    #home_hero_section .hero_left h1 {
        width: 100%;
        font-size: 66px;
        line-height: 90px;
        margin-bottom: 30px;
    }

    #home_hero_section .hero_left p {
        max-width: 100%;
    }

    .tcareConnectBtn {
        margin-bottom: 30px;
    }

    #home_hero_section .hero_right {
        max-width: 100%;
    }

    #home_hero_section .hero_right video {
        max-height: 390px;
        width: 100%;
        position: relative;
        left: 30%;
        border-radius: 30px;
    }

    #home_hero_section .hero_right .card_1 img.card_1_lavender {
        max-height: 99%;
        /* bottom: -50%; */
        bottom: -48%;
        left: 33%;
    }

    #home_hero_section .hero_right .card_1 img.card_1_ruby {
        max-height: 100%;
        bottom: -25%;
        left: 50%;
    }

    #home_hero_section .hero_right .card_1 img.card_1_mint {
        max-height: 100%;
        /* bottom: -40%; */
        bottom: -36%;
        left: 29%;
        width: 64%;
    }

    #home_hero_section .hero_right .card_1 img.card_1_periwinkle {
        max-height: 100%;
        bottom: -18%;
        left: 42%;
        width: 69%;
    }

    #description_section {
        padding: 0 20px;
        padding-bottom: 83px;
    }

    #description_section .description {
        width: 100%;
        margin: 0;
        margin-top: 30px;
        padding: 30px;
        font-size: 30px;
        height: fit-content;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    #video_2_section {
        padding-bottom: 180px;
    }

    #video_2_section .video_2_outer video {
        height: 580px;
        object-fit: cover;
        border-radius: 0 0 0 100px;
        -webkit-border-radius: 0 0 0 100px;
        -moz-border-radius: 0 0 0 100px;
        -ms-border-radius: 0 0 0 100px;
        -o-border-radius: 0 0 0 100px;
    }

    #video_2_section .video_2_inner {
        width: 100%;
        max-width: 350px;
        left: 30px;
        top: 40px;
        padding: 30px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }

    #video_2_section .video_2_inner h3 {
        font-size: 30px;
        line-height: 38px;
        margin: 0;
    }

    #video_2_section .video_2_inner p {
        font-size: 18px;
        line-height: 27px;
        margin: 0;
    }

    #video_2_section .video_2_outer .card_2 img.card_2_Mint {
        bottom: -31%;
        left: 50%;
        width: 47%;
        height: 200px;
    }

    #video_2_section .video_2_outer .card_2 img.card_2_Ruby {
        bottom: -17%;
        left: 30%;
        width: 60%;
    }

    #video_2_section .video_2_outer .card_2 img.card_2_Periwinkle {
        left: 10%;
        bottom: -8%;
        width: 50%;
    }

    #tcare_services_section {
        background-color: var(--darkPurple);
        padding-top: 53px;
    }

    #tcare_services_section .services_header {
        font-size: 54px;
        line-height: 77px;
        color: var(--cream);
        text-align: center;
        padding-bottom: 68px;
    }

    #tcare_services_section .services_row {
        padding: 0 30px;
    }

    #tcare_services_section .services_card {
        margin-bottom: 50px;
        font-size: 24px;
        line-height: 34px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 160px;
        max-width: 175px;
        margin-left: auto;
        margin-right: auto;
    }

    #tcare_services_section .services_card img {
        max-width: 50px;
    }

    #video_3_section .video_3_outer video {
        height: 500px;
        object-fit: cover;
        width: 100%;
        border-radius: 100px 0 0 0;
        -webkit-border-radius: 100px 0 0 0;
        -moz-border-radius: 100px 0 0 0;
        -ms-border-radius: 100px 0 0 0;
        -o-border-radius: 100px 0 0 0;
    }

    #video_3_section .video_3_inner {
        width: 100%;
        max-width: 350px;
        bottom: 30px;
        left: 30px;
        padding: 30px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }

    #video_3_section .video_3_inner h3 {
        font-size: 30px;
        line-height: 38px;
        margin: 0;
    }

    #video_3_section .video_3_inner p {
        font-size: 18px;
        line-height: 27px;
        margin: 0;
    }

    #video_3_section .video_3_outer .card_3 img.card_3_periwinkle {
        top: -10%;
        left: 10%;
        width: 50%;
    }

    #video_3_section .video_3_outer .card_3 img.card_3_mint {
        top: -18%;
        left: 35%;
        width: 30%;
    }

    #video_3_section .video_3_outer .card_3 img.card_3_darkPurple {
        top: -24%;
        left: 30%;
        width: 60%;
        border-radius: 40px;
    }

    #faq_section .wrapper {
        padding: 30px;
        padding-bottom: 100px;
        border-radius: 0 0 100px 0;
        -webkit-border-radius: 0 0 100px 0;
        -moz-border-radius: 0 0 100px 0;
        -ms-border-radius: 0 0 100px 0;
        -o-border-radius: 0 0 100px 0;
    }

    #faq_section .faq_header {
        font-size: 50px;
        line-height: 70px;
    }

    #faq_section ul {
        max-width: 100%;
    }

    #faq_section ul li {
        font-size: 24px;
        line-height: 36px;
        min-height: 100px;
    }

    #faq_section ul li:nth-of-type(3) {
        min-height: 80px;
    }

    #faq_section ul li:nth-of-type(4) {
        min-height: 150px;
    }

    #faq_section ul li a img {
        max-width: 80px;
    }

    #faq_section ul li .collapse_div {
        font-size: 18px;
        line-height: 28px;
    }

    #bottom_section {
        padding-right: 30px;
    }

    #bottom_section .wrapper {
        height: 443px;
    }

    #bottom_section .wrapper .card_4 {
        display: none;
    }

    #bottom_section .left {
        max-width: fit-content;
        width: 50%;
        top: -19%;
        height: 525px;
    }

    #bottom_section .left img {
        width: 100%;
        height: 100%;
        min-width: 200px;
    }

    #bottom_section .wrapper .right {
        padding: 20px;
        width: 100%;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }


    #bottom_section .wrapper .right h2 {
        /* font-size: 40px;
        line-height: 69px; */
        margin-bottom: 40px;
        max-width: 700px;

        font-size: 30px;
        line-height: 49px;
    }

    #footer_section .landing_footer {
        max-width: 100%;
        width: 100%;
        padding: 0 30px;
    }

    .footer_div {
        height: 100px;
    }

    /* request confirmation page */
    #support_hero_section .hero_main {
        padding: 30px;
    }

    #support_hero_section h1 {
        font-size: 60px;
    }

    #support_main_section .wrapper {
        padding: 50px 30px;
        border-radius: 0px 150px 0px 0px;
        -webkit-border-radius: 0px 150px 0px 0px;
        -moz-border-radius: 0px 150px 0px 0px;
        -ms-border-radius: 0px 150px 0px 0px;
        -o-border-radius: 0px 150px 0px 0px;
    }

    #support_main_section .wrapper .heading {
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        max-width: 904px;
        margin-bottom: 56px;
    }

    #support_main_section .wrapper .main_row {
        margin-left: 6px;
        margin-right: 6px;
    }

    #support_main_section .main_outer .main_left {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #support_main_section .main_outer .main_right {
        max-width: 100%;
    }

    #support_main_section .main_outer .main_right video {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }

    #support_main_section .main_outer .main_left .watchVideoBtn {
        order: 4;
        margin-top: 30px;
    }

    #support_footer .wrapper {
        padding: 0 30px;
    }

}


@media screen and (max-width:500px) {
    .my_navbar {
        padding: 10px 10px;
    }

    .my_navbar .navbar-brand {
        justify-content: space-between;
        flex-wrap: wrap;
    }

    #home_hero_section {
        padding-top: 30px;
    }

    #home_hero_section .hero_outer {
        padding: 0;
        width: 100%;
    }

    #home_hero_section .hero_left {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    #home_hero_section .hero_left h1 {
        width: 100%;
        font-size: 48px;
        line-height: 76px;
    }

    .tcareConnectBtn {
        margin-bottom: 30px;
    }

    #home_hero_section .hero_right {
        width: 100%;
        height: 100%;
        margin-right: auto;
        padding: 0;
    }

    #home_hero_section .hero_right video {
        width: 100%;
        height: 100%;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #home_hero_section .hero_right .card_1 {
        display: none;
    }

    #description_section {
        padding-bottom: 0;
    }

    #description_section .description {
        height: 100%;
        margin: 0;
        padding: 20px;
        font-size: 30px;
        line-height: 50px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #description_section .desc_col {
        padding: 0;
    }

    #description_section .description .tcareConnectBtn {
        margin-bottom: 10px;
    }

    #video_2_section {
        padding-bottom: 0;
        width: 100%;

    }

    #video_2_section .video_2_outer {
        width: 100%;
        height: 100%;
    }

    #video_2_section .video_2_outer video {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #video_2_section .video_2_inner {
        position: static;
        width: 100%;
        max-width: 100%;
        padding: 30px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #video_2_section .video_2_outer .card_2 {
        display: none;
    }

    #tcare_services_section {
        padding-top: 40px;
    }

    #tcare_services_section .services_header {
        font-size: 44px;
        line-height: 67px;
        color: var(--cream);
        text-align: center;
        padding-bottom: 48px;
    }

    #tcare_services_section .services_row {
        padding-bottom: 27px;
        padding-left: 0;
        padding-right: 0;
    }

    #tcare_services_section .services_card {
        margin-bottom: 70px;
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
    }

    #video_3_section {
        padding-top: 0;
    }

    #video_3_section .video_3_outer video {
        width: 100%;
        height: 100%;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #video_3_section .video_3_inner {
        position: static;
        width: 100%;
        max-width: 100%;
        padding: 30px;

        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #video_3_section .video_3_inner h3 {
        font-size: 35px;
    }

    #video_3_section .video_3_outer .card_3 {
        display: none;
    }

    #faq_section .wrapper {
        padding: 30px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #faq_section .faq_header {
        font-size: 44px;
        line-height: 67px;
        width: 100%;
    }

    #faq_section ul li {
        font-size: 24px;
        line-height: 34px;
        min-height: 80px;
    }

    #faq_section ul li:nth-of-type(3) {
        min-height: 120px;
    }

    #faq_section ul li a img {
        max-width: 80px;
    }

    #faq_section ul li .collapse_div {
        padding-right: 0;
        font-size: 18px;
        line-height: 30px;
    }

    #bottom_section .wrapper {
        padding: 30px;
        padding-bottom: 0;
    }

    #bottom_section .left {
        display: none;
    }

    #bottom_section .wrapper .right {
        padding: 0;
    }

    #bottom_section .wrapper .right h2 {
        font-size: 44px;
        line-height: 57px;
    }

    #bottom_section .wrapper .card_4 {
        display: none;
    }

    .tcareConnectBtn {
        min-height: 53px;
    }

    #footer_section .landing_footer {
        padding: 0 30px;
    }

    .footer_div {
        height: 100px;
    }


    /* requestconfirmation page */

    .navbar-collapse .visitTcareBtn {
        margin-top: 30px;
    }

    #support_hero_section .hero_main {
        padding: 20px 30px 40px 30px;
    }

    #support_hero_section h1 {
        font-size: 60px;
        line-height: 86px;
    }

    #support_main_section .wrapper {
        padding: 0;
        padding-bottom: 30px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #support_main_section .wrapper .heading {
        text-align: left;
        font-size: 30px;
        line-height: 48px;
        width: 100%;
        padding: 40px 30px;
        margin-bottom: 0;
    }

    #support_main_section .wrapper .main_outer {
        flex-wrap: wrap;
        padding: 38px 0;
        padding-bottom: 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #support_main_section .main_outer .main_left {
        padding: 40px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
    }

    /* #support_main_section .main_outer .main_left h3 {
        padding: 0 40px;
    }
    #support_main_section .main_outer .main_left p {
        padding: 0 40px;
    } */
    #support_main_section .main_outer .main_left .watchVideoBtn {
        margin: 30px 0;
        order: 4;
    }

    #support_main_section .main_outer .main_right {
        max-width: 100%;
        width: 100%;
    }

    #support_main_section .main_outer .main_right video {
        width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 256px;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
    }

    #support_main_section .wrapper .footer_logo img {
        max-width: 150px;
    }

    #support_footer .wrapper {
        padding: 0 30px;
    }

}


@media screen and (max-width:480px) {
    /* #home_section div{
        padding: 0;
    } */

    .my_navbar {
        padding: 10px 0px;
    }

    .my_navbar .navbar-brand {
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .my_navbar .tcareNavbarLogo {
        max-width: 110px;
    }

    .my_navbar .prudentialLogoBlue {
        max-width: 130px;
    }

    .navbar_divider {
        margin: 0 5px 0 10px;
        border-right: 2px solid;
    }

    #home_hero_section {
        padding-top: 20px;
    }

    #home_hero_section .hero_left {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    #home_hero_section .hero_left h1 {
        width: 100%;
        max-width: 300px;
    }

    .tcareConnectBtn {
        min-height: 43px;
        height: fit-content;
        font-size: 14px;
    }

    .tcareConnectBtn img {
        max-width: 20px;
    }

    #description_section .description {
        padding: 10px;
    }

    #description_section .description p {
        /* max-width: 300px; */
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    #description_section .description .tcareConnectBtn {
        margin-left: auto;
        margin-right: auto;
    }

    #video_2_section .video_2_inner {
        padding: 20px;
    }

    #video_2_section .video_2_inner h3 {
        font-size: 38px;
        text-align: center;
        margin: 0 auto;
    }

    #tcare_services_section {
        padding-top: 20px;
    }

    #tcare_services_section .services_header {
        font-size: 38px;
        line-height: 67px;
        padding-bottom: 40px;
    }

    #tcare_services_section .services_card img {
        max-width: 40px;
    }

    #tcare_services_section .services_card p {
        font-size: 24px;
    }

    #tcare_services_section .services_row {
        padding-bottom: 0;
    }

    #video_3_section .video_3_inner {
        padding: 20px;
    }

    #video_3_section .video_3_inner h3 {
        font-size: 38px;
        /* text-align: center; */
        margin: 0 auto;
    }

    #faq_section .wrapper {
        padding: 20px;
    }

    #faq_section .faq_header {
        font-size: 38px;
        line-height: 60px;
        width: 100%;
    }

    #faq_section ul li {
        font-size: 20px;
        line-height: 30px;
        min-height: 60px;
    }

    #faq_section ul li:nth-of-type(3) {
        min-height: 90px;
    }

    #faq_section ul li:nth-of-type(4) {
        min-height: 150px;
    }

    #faq_section ul li a img {
        max-width: 70px;
    }

    #faq_section ul li .collapse_div {
        font-size: 16px;
        line-height: 26px;
        margin: 0;
    }

    #faq_section ul li .collapse_div p {
        padding: 20px 0;
        margin: 0;
    }

    #bottom_section .wrapper {
        padding: 20px;
    }

    #bottom_section .wrapper .right {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    #bottom_section .wrapper .right h2 {
        font-size: 33px;
        line-height: 50px;
        text-align: center;
        margin-bottom: 20px;
        max-width: 321px;
        margin-left: auto;
        margin-right: auto;
    }

    #bottom_section .wrapper .right .tcareConnectBtn {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    #footer_section .landing_footer {
        padding: 0 20px;
    }

    /* thanks page */

    #support_hero_section .hero_main {
        padding: 20px;
    }

    #support_hero_section h1 {
        font-size: 50px;
        line-height: 66px;
    }

    #support_main_section .wrapper .heading {
        text-align: left;
        font-size: 26px;
        line-height: 38px;
        padding: 30px 20px;
        text-align: center;
    }

    #support_main_section .wrapper .main_outer {
        padding: 0;
    }

    #support_main_section .main_outer .main_left {
        padding: 30px;
    }

    #support_main_section .main_outer .main_left h3 {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 40px;
    }

    #support_main_section .main_outer .main_left p {
        font-size: 20px;
        line-height: 30px;
    }

    #support_main_section .main_outer .main_left .watchVideoBtn {
        margin: 20px 0;
        height: fit-content;
        padding: 0 20px;
        min-height: 43px;
        font-size: 14px;
        margin-bottom: 0;
    }

    #support_main_section .main_outer .main_left .watchVideoBtn img {
        max-width: 20px;
    }

    #support_main_section .wrapper .footer_logo img {
        max-width: 110px;
    }
}


@media screen and (max-width:360px) {
    .container {
        width: 360px !important;
        max-width: 360px !important;
    }

    section {
        width: 360px !important;
        max-width: 360px !important;
    }
}